<template>
  <v-container fluid class="pa-0">
    <!-- <cart-button /> -->
    <template v-if="course">
      <dialog-video
        v-model="videoDialog"
        :videoURLS="videoURLS"
        :title="title"
      />
      <share-modal ref="shareModalRef" :product="course" />
      <course-header
        :title="course.title"
        :level="course.level"
        :rate="course.rate"
        :routes="[
          { title: 'خانه', url: '/' },
          { title: 'دسته بندی', url: '/categories' },
          { title: 'دوره ها', url: '/courses' },
          { title: course.title },
        ]"
        :date="course.end_date_offer"
        :img="course.media ? course.media.url : undefined"
      />
    </template>

    <v-row class="grey lighten-3 pb-4" justify="center" no-gutters>
      <v-col cols="12" md="8" lg="9" order="2" order-md="1">
        <v-card class="rounded-xl ma-2 moholand-shadow">
          <v-card-text class="text-justify black--text">
            <v-img
              v-if="course.video || course.demo_url"
              max-height="400"
              class="rounded-xl"
              :src="
                course.media
                  ? course.media.url
                  : require('../../assets/img/thumb.jpg')
              "
              @click="
                () =>
                  course.video && course.video.url
                    ? playVideo(course.video.url)
                    : course.demo_url && playVideo(course.demo_url)
              "
            >
              <v-overlay absolute v-if="course.video || course.demo_url">
                <v-btn icon style="width: 100%; height: 100%" class="rounded-0">
                  <v-icon
                    color="white"
                    :size="$vuetify.breakpoint.smAndDown ? 100 : 200"
                  >
                    mdi-play-circle-outline
                  </v-icon>
                </v-btn>
              </v-overlay>
            </v-img>
            <title-heading title="توضیحات" class="my-4" />
            <div
              v-if="course.desc"
              v-html="course.desc"
              class="fix-html-images"
            />
            <title-heading title="سرفصل ها" class="my-4" />
            <h4
              class="grey--text text-center"
              v-if="course.chapter.length === 0"
            >
              هیچ سرفصلی برای این دوره ثبت نشده است
            </h4>
            <course-lessons
              :data="course.chapter"
              @playVideo="playVideo"
              :isBought="course.is_bought"
            />
            <title-heading
              title="فایل های فشرده و مثال ها"
              class="my-4"
              v-if="course.files_media && course.files_media.length"
            />
            <v-card
              class="moholand-shadow my-4 rounded-lg"
              v-for="(gallery, i) in courseFiles"
              :key="'gallery' + i"
            >
              <v-card-text class="d-flex flex-wrap align-center py-1">
                <v-icon
                  v-if="course.is_bought"
                  large
                  color="green lighten-2"
                  class="ml-2"
                >
                  mdi-download
                </v-icon>
                <v-icon v-else color="grey" class="ml-2">
                  mdi-lock
                </v-icon>
                <h2 dir="rtl">
                  {{ gallery.title }}
                </h2>
                <v-spacer />
                <v-icon color="grey" class="ml-2">
                  mdi-lock
                </v-icon>
                <small>
                  رمز فایل: moholand.com
                </small>
                <v-spacer />
                <v-btn
                  large
                  :color="course.is_bought ? 'success' : 'grey'"
                  class="px-8 ma-4 rounded-lg"
                  :disabled="!course.is_bought"
                  @click="download(gallery.url || gallery)"
                >
                  دانلود
                </v-btn>
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-card>
        <comments
          :inputsGrid="{ cols: 12, sm: 6 }"
          :commentsGrid="{ cols: 12, sm: 6 }"
          :model_id="course.id"
          :model_slug="course.slug"
          comment_type="course"
          class="ma-2"
        />
      </v-col>
      <v-col cols="12" md="4" lg="3" order="1" order-md="2">
        <v-card class="text-center rounded-xl ma-2 moholand-shadow">
          <v-card-text class="black--text">
            <h4 class="mt-4">
              <span class="text-large">{{ course.participators }}</span>
              نفر در این دوره شرکت کرده اند.
            </h4>
            <v-row class="mt-2">
              <v-col cols="12" md="6" class="d-flex justify-center">
                <v-img
                  :src="require('../../assets/icons/list.png')"
                  class="grey-icon icon-small"
                />
                <div class="my-auto mr-2 grey--text">
                  <span class="text-medium font-weight-bold">
                    {{ course.chapter_count }}
                  </span>
                  فصل
                </div>
              </v-col>
              <v-col cols="12" md="6" class="d-flex justify-center">
                <v-img
                  :src="require('../../assets/icons/clock.png')"
                  class="grey-icon icon-small"
                />
                <div class="my-auto mr-2 grey--text">
                  <span class="text-medium font-weight-bold">
                    {{ course.duration }}
                  </span>
                  ساعت
                </div>
              </v-col>
            </v-row>
            <template v-if="isValidDiscount">
              <h2 class="mt-5" v-if="!course.offer_price">
                قیمت: {{ Intl.NumberFormat().format(course.price) }} تومان
              </h2>
              <div v-else class="my-8">
                <strike class="mt-5 grey--text">
                  قیمت: {{ Intl.NumberFormat().format(course.price) }} تومان
                </strike>
                <h2 class="mt-5">
                  قیمت با تخفیف:
                  {{ Intl.NumberFormat().format(course.offer_price) }} تومان
                </h2>
              </div>
            </template>
            <h2 v-else class="mt-5">
              قیمت: {{ Intl.NumberFormat().format(course.price) }} تومان
            </h2>
            <v-btn
              v-if="!course.is_bought"
              block
              x-large
              color="success"
              class="mt-4 rounded-lg"
              :loading="loading"
              @click="reserve"
            >
              همین حالا بخر!
            </v-btn>

            <template v-if="course.user">
              <v-img
                :src="course.user.avatar_url"
                class="product-img rounded-xl orange darken-1 mx-auto mt-4"
              />
              <h1 class="my-5">
                {{ course.user.first_name }}
                {{ course.user.last_name }}
              </h1>
              <title-heading title="درباره استاد" v-if="course.user.desc" />
              <div class="text-justify pa-4" v-if="course.user.desc">
                {{ course.user.desc }}
              </div>
            </template>
            <title-heading
              title="پیش نیاز ها"
              v-if="course.pre_requirements && course.pre_requirements.length"
            />
            <v-row no-gutters>
              <v-col
                cols="12"
                class="mt-2 pa-1"
                v-for="(item, i) in course.pre_requirements"
                :key="'presquites' + i"
              >
                <v-btn
                  v-if="item.requirement"
                  block
                  dark
                  link
                  :to="'/courses/' + item.requirement.slug"
                  color="orange darken-1"
                  class="rounded-lg"
                >
                  {{ item.requirement.title }}
                </v-btn>
              </v-col>
            </v-row>
            <title-heading title="به دوستات معرفی کن" class="mt-4" />
            <v-row>
              <v-col>
                <my-clip-board-link
                  :url="origin + $route.fullPath"
                  class="mt-4 rtl"
                />
              </v-col>
            </v-row>
            <v-row justify="center" class="mb-4">
              <v-btn x-large class="rounded-lg mt-3" @click="openShareModal">
                <v-icon>
                  mdi-share-variant
                </v-icon>
                به اشتراک گذاری
              </v-btn>
            </v-row>
            <!-- <div class="d-flex mt-4 justify-center">
              <v-btn
                fab
                v-for="i in 4"
                :key="i"
                class="rounded-lg ma-2"
                color="red"
                dark
              >
                <v-icon large>mdi-instagram</v-icon>
              </v-btn>
            </div> -->
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <similar-courses :slug="slug" />
  </v-container>
</template>

<script>
import CourseItem from "../../components/CourseItem.vue";
import DialogVideo from "../../components/DialogVideo.vue";
import MyClipBoardLink from "../../components/MyClipBoardLink.vue";
import Comments from "../../components/Pages/Comments.vue";
import CourseHeader from "../../components/Pages/CourseHeader.vue";
import CourseLessons from "../../components/Pages/CourseLessons.vue";
import SimilarCourses from "../../components/Pages/SimilarCourses.vue";
import TitleHeading from "../../components/TitleHeading.vue";
import { Constants } from "../../constants/GlobalConstants";
import MyAxios from "../../constants/MyAxios";
import { getUnixDifferenceTimeFromNow } from "../../constants/TimeFunctions";
import ShareModal from "@/components/Pages/SharePostModal.vue";
export default {
  components: {
    Comments,
    CourseHeader,
    TitleHeading,
    MyClipBoardLink,
    CourseLessons,
    CourseItem,
    SimilarCourses,
    DialogVideo,
    ShareModal,
  },
  data() {
    return {
      mainURL: Constants.mainURL,
      slug: this.$route.params.slug || "",
      origin: window.location.origin,
      course: null,
      similar: [],
      videoDialog: false,
      videoURLS: [],
      loading: false,
      title: "",
    };
  },
  created() {
    this.GetData();
  },
  methods: {
    openShareModal() {
      this.$refs.shareModalRef.modal = true;
    },
    GetData: function() {
      this.$root.$emit("globalLoadingSet", true);
      MyAxios.get("/courses/index/" + this.slug, {
        params: {
          noPaginate: true,
          with: ["chapter", "user", "pre_requirements", "video"],
        },
      })
        .then((response) => {
          console.log(response.data[0]);
          this.course = response.data[0];
          this.$root.$emit("globalLoadingSet", false);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    playVideo(url, title = "") {
      if (url) {
        this.videoURLS = [
          {
            src: url || "",
            // src: item.video.url,
            type: "video/mp4",
          },
        ];
        this.title = title;
        this.videoDialog = true;
      }
    },
    reserve() {
      this.loading = true;
      this.$root.$emit(
        "addToCart",
        this.course,
        () => {
          this.loading = false;
        },
        "course"
      );
    },
    download(url) {
      window.open(url?.link || url, "_blank");
    },
  },
  watch: {
    "$route.params.slug"(newSlug, oldSlug) {
      this.slug = newSlug;
      this.GetData();
    },
  },
  computed: {
    isValidDiscount() {
      const diff = getUnixDifferenceTimeFromNow(this.course?.end_date_offer);
      return diff && diff < 0;
    },
    courseFiles() {
      if (!this.course) return [];

      let files = JSON.parse(this.course.files || "[]");

      return this.course.files_media
        ? this.course.files_media
            .filter((val, i) => {
              if (val) return true;
              else {
                files.splice(i, 1);
                return false;
              }
            })
            .map((val, i) =>
              val.id
                ? { media_id: val, title: files[i] ? files[i].title : "" }
                : { link: val, title: files[i] ? files[i].title : "" }
            )
        : [];
    },
  },
};
</script>

<style scoped>
.product-img {
  /* width: 20em;
    height: 20em; */
  border: 5px solid;
  max-height: 150px;
  max-width: 150px;
}
</style>
