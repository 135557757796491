<template>
  <div class="grey lighten-3">
    <v-container fluid class="simple-header pa-4 white--text">
      <my-routes-list
        :routes="routes"
        class="mt-4 justify-center justify-md-start"
      />
      <v-container>
        <v-row style="z-index: 2">
          <v-col cols="12" md="2">
            <v-img
              :src="img"
              class="sp-img rounded-xl elevation-0 mx-md-0 mx-auto"
            />
          </v-col>
          <v-col cols="12" md="7" class="text-center text-md-start">
            <h1>{{ title }}</h1>
            <div
              class="d-flex flex-wrap justify-center justify-md-start align-center"
            >
              <v-chip
                class="ma-2 rounded-lg"
                text-color="white"
                label
                large
                color="orange"
              >
                سطح
                {{ categories[level] }}
              </v-chip>
              <v-rating color="yellow" dark :value="rate" large readonly />
            </div>
          </v-col>
          <v-col cols="12" md="3" class="text-center text-md-start">
            <my-countdown v-if="date" :endDate="date" />
          </v-col>
        </v-row>
      </v-container>
      <!-- <div class="content">
            </div> -->
    </v-container>
  </div>
</template>

<script>
import { Constants } from "../../constants/GlobalConstants";
import MyCountdown from "../MyCountdown.vue";
import MyRoutesList from "../MyRoutesList.vue";
export default {
  components: { MyRoutesList, MyCountdown },
  data() {
    return {
      categories: Constants.coursesCategories,
    };
  },
  props: {
    routes: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    level: {
      type: String,
      required: true,
    },
    rate: {
      type: String,
      default: 0,
    },
    date: {
      type: Date,
    },
    img: {
      type: String,
      default: require("../../assets/img/thumb.jpg"),
    },
  },
};
</script>

<style scoped>
.simple-header {
  position: relative;
  width: 100%;
  z-index: 1;
  background: url("../../assets/header.jpg");
}
.sp-img {
  /* width: 20em;
    height: 20em; */
  max-width: 250px;
  max-height: 150px;
  border: 5px white solid;
  /* position: absolute; */
  right: 0;
}
</style>
