<template>
  <v-dialog ref="dialog" v-model="modal" width="400px">
    <v-card class="text-center rounded-lg">
      <v-card-text>
        <v-form ref="form">
          <v-row justify="center">
            <v-col md="9" cols="12">
              <h1 class="orange--text darken-1 my-8">
                اشتراک گذاری
              </h1>
              <v-btn
                block
                large
                dark
                color="blue"
                :loading="loading"
                @click="share('telegram')"
                class="mt-2"
              >
                تلگرام
              </v-btn>
              <v-btn
                block
                large
                dark
                color="success"
                :loading="loading"
                @click="share('twitter')"
                class="mt-2"
              >
                توییتر
              </v-btn>
              <v-btn
                block
                large
                dark
                color="red"
                :loading="loading"
                @click="share('facebook')"
                class="mt-2"
              >
                فیسبوک
              </v-btn>
              <v-btn
                block
                large
                color="yellow"
                :loading="loading"
                @click="share('linkedin')"
                class="mt-2"
              >
                لینکدین
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      modal: false,
      loading: false,
      origin: window.location.origin,
    };
  },
  methods: {
    share(type = "telegram") {
      let Url = this.origin + this.$route.fullPath;
      switch (type) {
        case "twitter":
          this.navigate(
            `https://twitter.com/intent/tweet?url=${Url}&text=${this.product.title}`
          );
          break;
        case "telegram":
          this.navigate(`https://telegram.me/share/url?url=${Url}`);
          break;
        case "facebook":
          this.navigate(`http://www.facebook.com/sharer.php?u=${Url}`);
          break;
        case "linkedin":
          this.navigate(
            `http://linkedin.com/shareArticle?mini=true&amp;title=${this.product.title}&amp;url=${Url}`
          );
          break;
      }
    },
    navigate(url) {
      window.open(url, "_blank");
    },
    onOpen() {
      this.modal = true;
    },
    onClose() {
      this.modal = false;
    },
  },
};
</script>

<style scoped>
.ico-shadow {
  box-shadow: 0 0 0px 7px #cbcbcb, 0 0 4px 13px #dcdcdc;
}
</style>